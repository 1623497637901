import React from 'react'

export const PageNotFound = () => {
  return (
    <div  className=' p-5'>
        <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  )
}
