import React from "react";

export const Boxes = () => {
  return (
    <div>
      <div className="p-md-5 p-2">
        <div className="row w-100 m-0 justify-content-center pt-lg-0 pb-lg-0 p-lg-5 p-1">
          <div
            className="col-lg-6 col-12 "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <div className="BlackBox fourBoxes">
              <h3 className="BoxesTitle" style={{ padding: "10px 10px" }}>
                ACCURACY
              </h3>
              <p className="BoxesParagraph">
                Over the past few years, the trust placed by our clients has
                been the building block for our determined and tireless efforts.
                The way our team has been tremendously showing the results, that
                motivation is derived from the faith and belief shown by our
                prestigious clients, We assure that this stature will be
                continued. Because, over the years, providing accurate results
                and precise data to the clients has been the secret to our
                success.
              </p>
            </div>
          </div>

          <div
            className="col-lg-6 col-12 "
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <div className="OrangeBox fourBoxes">
              <h3 className="BoxesTitle" style={{ padding: "10px 10px" }}>
                EFFICENCY
              </h3>
              <p className="BoxesParagraph">
                When it comes to delivering the output, our team is skilled,
                talented and most substantially, TRAINED. We are 24/7 in touch
                with constructors, builders, we have employed people carrying an
                entire background and future in this particular field of COST
                ESTIMATION. Furthermore, we take the most pride in our market
                research. You can even choose us solely for the consultation
                purposes because we understand the market far better than our
                competitors. 
              </p>
            </div>
          </div>
        </div>
        <div className="row w-100 m-0 justify-content-center  pt-lg-0 pb-lg-0 p-lg-5 p-1">
          <div
            className="col-lg-6  order-lg-1 col-12 order-md-2  order-sm-2 order-2"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <div className="OrangeBox fourBoxes">
              <h3 className="BoxesTitle" style={{ padding: "10px 10px" }}>
                DEDICATION
              </h3>
              <p className="BoxesParagraph">
                At Procore Estimators, we are dedicated to providing accurate
                and reliable estimates for our clients. We understand the
                importance of precise cost projections in helping businesses
                make informed decisions and plan their projects effectively. Our
                dedication lies in offering our clients a trustworthy and
                dependable resource that they can rely on for their project
                planning and budgeting needs.
              </p>
            </div>
          </div>

          <div
            className="col-lg-6  order-lg-2 col-12 order-md-1 order-sm-1 order-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px",
            }}
          >
            <div className="BlackBox fourBoxes">
              <h3 className="BoxesTitle" style={{ padding: "10px 10px" }}>
                RELIABILITY
              </h3>
              <p className="BoxesParagraph">
                In the world of construction estimating, dedication is the
                cornerstone upon which our successes are built. Our commitment
                to excellence goes beyond numbers – it's about the unwavering
                determination to deliver results that stand as a testament to
                our values. ProCore Estimators is a professional platform to
                provide Cost Estimating Services to different clients with
                accuracy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
