import React from 'react';
import LogoChanged from '../Images/LogoChanged.jpeg'

export const LogoFile = () => {
  return (
    <div>
        <img src={LogoChanged} alt="" height={500} width={500} />
    </div>
  )
}
