import React from "react";

import SpecialConstructionHTHI from "../Images/SpecialConstructionHTHI.webp";
import { GetStartedwithUs } from "../Common_Components/GetStartedwithUs";
import { LookAtTrades } from "../Common_Components/LookAtTrades";
import { Banner } from "../Common_Components/Banner";
import { HalfTextHalfImg } from "../Common_Components/HalfTextHalfImg";
import pagenamecontainerpic from "../Images/SpecialConstructionPNCpic.webp";
import { CommonHeading } from "../Common_Components/CommonHeading";
import { PageNameContainer } from "../Common_Components/PageNameContainer";
import { Helmet } from "react-helmet";

export const SpecialConstruction = () => {
 
  const text = (
    <>
      Our estimating company focus is on delivering precise and comprehensive
      projections for bespoke construction endeavors. Our team of specialists is
      committed to assisting you in organizing and allocating resources for the
      distinctive aspects that set your project apart.
    </>
  );
  return (
    <div>
      <Helmet>
          <title>
          Special Construction Estimating Services
          </title>

          <meta
            name="description"
            content="Explore our Special Construction Cost Estimating Services. Accurate pricing tailored for projects. Trust The Procore Estimators for precision in every detail."
          />
        </Helmet>
      <PageNameContainer
        Pagename="Special Construction Estimation"
        src={pagenamecontainerpic}
      />
      <div className=" container mt-5">
        <HalfTextHalfImg text={text} src={SpecialConstructionHTHI} />
        <CommonHeading text="Our Special Construction Estimation Process" />
        <div>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Initial Consultation:
            </span>
            We begin by talking about your project objectives, design tastes,
            and financial limitations. This enables us to comprehend the
            distinct needs and vision of your specialized construction project.
          </p>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Site Evaluation:
            </span>
            If needed, we carry out an in-depth site assessment to identify any
            particular factors that might influence construction techniques,
            material options, or project schedules.
          </p>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Specialized Material and Equipment Recommendations:
            </span>
            Depending on your project's specifics, we suggest a range of
            materials, machines, and equipment customized to your particular
            requirements, be it for an industrial facility, a healthcare center,
            a commercial building, or an educational establishment.
          </p>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Detailed Estimate:
            </span>
            Our team offers an extensive analysis of the projected costs for
            each kind of material, equipment, and labor involved. This covers
            quantities and any extra costs such as specialized installations or
            safety precautions.
          </p>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Presentation and Consent:
            </span>
            We show the estimate to you, clarifying each component and
            addressing any inquiries or issues you might have. After approval,
            we proceed with procurement.
          </p>
          <p className=" paragraph">
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Procurement and Construction Supervision:
            </span>
            We handle the procurement procedure to ensure that materials and
            equipment adhere to the defined standards. Our team can also
            collaborate with contractors to monitor proper construction and
            execution.
          </p>
          <CommonHeading text="What We Estimate" />
        <ul className=" paragraph">
          <li>
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Specialized Materials:
            </span>
            Featuring high-strength alloys, tailored concrete mixtures, and
            distinct building materials designed to meet your project's demands.
          </li>
          <li>
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Specialized Equipment:
            </span>
            This includes heavy machinery, precise instruments, or dedicated tools needed for the bespoke requirements of your specialized construction project. 
          </li>
          <li>
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Safety Measures and Compliance:
            </span>
            Incorporating specialized safety gear, fire control systems, and other precautions necessary to fulfill sector-specific regulations. 
          </li>
          <li>
            {" "}
            <span
              style={{
                color: "#FF7518",
                fontWeight: "700",
                marginRight: "10px",
              }}
            >
              Technology Integration: 
            </span>
            Examples include state-of-the-art HVAC systems, intelligent building technologies, or custom infrastructure essential for contemporary facilities.
          </li>
        </ul>
        </div>
        
      </div>
      <GetStartedwithUs />
      <div className="container">
        <LookAtTrades  />
      </div>
      <Banner />
    </div>
  );
};
